/* ======================================================================== */
/* 15. PJAX Update Language Switcher */
/* ======================================================================== */
function PJAXUpdateLanguageSwitcher(data) {

	return new Promise(function (resolve, reject) {

		var $currentSwitcher = $('.lang-switcher');

		if (!$currentSwitcher.length) {
			resolve(true);
			return;
		}

		var
			rawHTML = $.parseHTML(data.next.html, document, true), // make sure to parse <script> tags as well
			$newSwitcher = $(rawHTML).find('.lang-switcher'),
			$trpSwitcher = $newSwitcher.find('.trp-language-switcher'); // TranslatePress language switcher

		$currentSwitcher.replaceWith($newSwitcher);

		// eval language switcher inline scripts
		$newSwitcher.find('script').each(function () {
			try {
				window.eval(this.text);
			} catch (error) {
				console.warn(error);
			}
		});

		// reset width of TranslatePress language switcher
		if ($trpSwitcher.length) {
			TweenMax.set($newSwitcher.find('.trp-ls-shortcode-language, .trp-ls-shortcode-current-language'), {
				clearProps: 'width'
			});
		}

		resolve(true);

	});

}
