/* ======================================================================== */
/* 87. elementor */
/* ======================================================================== */
/**
 * Elementor Preview
 */
window.$window.on('elementor/frontend/init', function () {

	if (typeof elementor !== 'undefined') {

		elementorFrontend.hooks.addAction('frontend/element_ready/global', function ($scope) {
			doSplitText($scope);
			setLines();
			setChars();
			window.PagePreloader.finish();
			window.$body.removeClass('cursor-progress');
			new SectionMasthead(window.$document);
			initComponents($scope);
			window.SMController.update(true);
			window.SMController.enabled(true);
		});

	}

});
