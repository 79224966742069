/* ======================================================================== */
/* 81. fixMobileBarHeight */
/* ======================================================================== */
function fixMobileBarHeight() {

	var vh;

	/**
	 * Initial set
	 */
	createStyleElement();
	setVh();

	if (window.theme.mobileBarFix.update) {
		/**
		 * Resize handling (with debounce)
		 */
		$(window).on('resize', debounce(function () {
			setVh();
		}, 250));
	}

	/**
	 * 100vh elements height correction
	 */
	function setVh() {

		vh = window.innerHeight * 0.01;

		$('#cassio-fix-bar').html(':root { --fix-bar-vh: ' + vh + 'px; }\n');

	}

	function createStyleElement() {

		if (!$('#cassio-fix-bar').length) {
			$('head').append('<style id=\"cassio-fix-bar\"></style>');
		}

	}

}
