/* ======================================================================== */
/* 26. EffectDistortion */
/* ======================================================================== */
var EffectDistortion = function (opts) {

	var vertex = `
			varying vec2 vUv;
			void main() {
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
	`;
	var fragmentHorizontal = `
			varying vec2 vUv;

			uniform sampler2D texture;
			uniform sampler2D texture2;
			uniform sampler2D disp;

			uniform float dispFactor;
			uniform float effectFactor;

			void main() {

					vec2 uv = vUv;

					vec4 disp = texture2D(disp, uv);

					vec2 distortedPosition = vec2(uv.x + dispFactor * (disp.r*effectFactor), uv.y);
					vec2 distortedPosition2 = vec2(uv.x - (1.0 - dispFactor) * (disp.r*effectFactor), uv.y);

					vec4 _texture = texture2D(texture, distortedPosition);
					vec4 _texture2 = texture2D(texture2, distortedPosition2);

					vec4 finalTexture = mix(_texture, _texture2, dispFactor);

					gl_FragColor = finalTexture;

			}
	`;
	var fragmentVertical = `
			varying vec2 vUv;

			uniform sampler2D texture;
			uniform sampler2D texture2;
			uniform sampler2D disp;

			uniform float dispFactor;
			uniform float effectFactor;

			void main() {

					vec2 uv = vUv;

					vec4 disp = texture2D(disp, uv);

					vec2 distortedPosition = vec2(uv.x, uv.y - dispFactor * (disp.r*effectFactor));
					vec2 distortedPosition2 = vec2(uv.x, uv.y + (1.0 - dispFactor) * (disp.r*effectFactor));

					vec4 _texture = texture2D(texture, distortedPosition);
					vec4 _texture2 = texture2D(texture2, distortedPosition2);

					vec4 finalTexture = mix(_texture, _texture2, dispFactor);

					gl_FragColor = finalTexture;

			}
	`;
	var aspect = opts.aspectRatio || 1.6;
	var canvas = opts.canvas;
	var dispImage = opts.displacementImage;
	var images = opts.images;
	var scene = new THREE.Scene();
	var camera;
	var animFrame;
	var textures = [];
	var renderer = new THREE.WebGLRenderer({
		canvas: canvas,
		powerPreference: 'high-performance'
	});
	var loader = new THREE.TextureLoader();
	var disp = loader.load(dispImage);
	var mat = new THREE.ShaderMaterial({
		uniforms: {
			effectFactor: {
				type: "f",
			},
			dispFactor: {
				type: "f",
				value: 0.0
			},
			texture: {
				type: "t",
				value: textures[0]
			},
			texture2: {
				type: "t",
				value: textures[1]
			},
			disp: {
				type: "t",
				value: disp
			}
		},
		vertexShader: vertex,
		fragmentShader: opts.slider.params.direction == 'horizontal' ? fragmentHorizontal : fragmentVertical,
		opacity: 1
	});
	var position = calculatePosition();
	var render;

	camera = new THREE.OrthographicCamera(
		position[0] / -2,
		position[0] / 2,
		position[1] / 2,
		position[1] / -2,
		1,
		1000
	);

	createScene();

	render = function () {
		if (renderer) {
			renderer.render(scene, camera);
			animFrame = requestAnimationFrame(render);
		}
	};

	function createScene() {

		if (!scene || !renderer || !camera) {
			return;
		}

		var geometry = new THREE.PlaneBufferGeometry(
			position[0],
			position[1],
		);

		var object = new THREE.Mesh(geometry, mat);

		renderer.setPixelRatio(1); // window.devicePixelRatio
		renderer.setClearColor(0xffffff, 0.0);
		renderer.setSize(position[0], position[1]);

		scene.add(object);

		camera.aspect = aspect;
		camera.position.z = 1;
		camera.updateProjectionMatrix();

		$window.on('resize', debounce(function () {
			position = calculatePosition();
			renderer.setSize(position[0], position[1]);
			camera.updateProjectionMatrix();
		}, 250));

		disp.wrapS = disp.wrapT = THREE.RepeatWrapping;

		for (var i = 0; i < images.length; i++) {

			var texture = loader.load(images[i]);

			texture.magFilter = THREE.LinearFilter;
			texture.minFilter = THREE.LinearFilter;
			texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

			textures[i] = texture;

		};

	}

	function calculatePosition() {

		var
			height = parseFloat(window.outerHeight),
			width = parseFloat(height * aspect),
			multiplier = 1,
			result = [];

		if (aspect > 1) {
			multiplier = window.innerWidth > width ? window.innerWidth / width : 1;
		} else {
			multiplier = canvas.clientWidth / width;
		}

		if (multiplier < 1) {
			multiplier = 1;
		}

		result[0] = width * multiplier;
		result[1] = height * multiplier;

		return result;

	}

	this.change = function (opts) {

		if (!mat) {
			return;
		}

		var
			indexFrom = opts.indexFrom || '0',
			indexTo = opts.indexTo || '0',
			speedIn = opts.speedIn || 1.2,
			intensity = opts.intensity || 0.25,
			easing = opts.easing || Power3.easeOut;

		mat.uniforms.texture.value = textures[indexFrom];
		mat.uniforms.texture2.value = textures[indexTo];
		mat.uniforms.effectFactor.value = intensity;

		TweenMax.fromTo(mat.uniforms.dispFactor, speedIn, {
			value: 0
		}, {
			value: 1,
			ease: easing
		});
	};

	this.animate = function () {
		render();
	};

	this.destroy = function () {
		cancelAnimationFrame(animFrame);
		renderer = undefined;
		camera = undefined;
		scene = undefined;
		loader = undefined;
		mat = undefined;
		$window.off('resize');
		opts.slider.off('slideChange');
	};

};
