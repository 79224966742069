/* ======================================================================== */
/* 4. PJAX Finish Loading */
/* ======================================================================== */
function PJAXFinishLoading(data) {

	return new Promise(function (resolve, reject) {

		var
			tl = new TimelineMax();

		TweenMax.to(window.$spinner, 1.2, {
			autoAlpha: 0
		});

		tl
			.add(function () {

				// audio backgrounds
				if (typeof window.AudioBackground !== 'undefined') {

					if (window.AudioBackground.isAudioSrc()) {
						window.AudioBackground.controller.reveal(true);
					} else {
						window.AudioBackground.controller.reveal(false);
					}
				}

				window.SMController.enabled(true);
				window.SMController.update(true);
				window.$pageHeader.attr('data-header-animation', '');
			})
			.add(function () {
				if (!$('.section-masthead_big-heading[data-os-animation]').length) {
					lockScroll(false);
				}
			}, '1.2')
			.add(function () {
				window.$body.removeClass('cursor-progress');
				window.InteractiveCursor.finishLoading();

				// Transition ended event
				window.dispatchEvent(new CustomEvent('arts/barba/transition/end'));
			})
			.add(function () {
				resolve(true);
			});

	});

}
