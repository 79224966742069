/* ======================================================================== */
/* 6. PJAX Init New Page */
/* ======================================================================== */
function PJAXInitNewPage(data) {

	return new Promise(function (resolve, reject) {

		var
			$nextContainer = $(data.next.container),
			$elementorSections = $nextContainer.find('.elementor-section'),
			$cf7Forms = $nextContainer.find('.wpcf7-form');

		PJAXUpdateAudioBackground(data).then(function () {

			Promise
				.all([
					PJAXUpdateBody(data),
					PJAXUpdateNodes(data),
					PJAXUpdateHead(data),
					PJAXUpdateAdminBar(data),
					PJAXUpdateLanguageSwitcher(data),
					fontObserver(),
				])
				.then(function () {
					return doSplitText($nextContainer);
				})
				.then(function () {
					return setLines($nextContainer);
				})
				.then(function () {
					return setChars($nextContainer);
				})
				.then(function () {

					// Elementor Pro sticky effects handling
					TweenMax.set($elementorSections, {
						clearProps: 'all',
						className: '-=elementor-sticky--active'
					});
					$nextContainer.find('.elementor-sticky__spacer').remove();

					// Elementor Animated Headline reset
					$nextContainer.find('.elementor-headline-animation-type-typing .elementor-headline-dynamic-wrapper').empty();

					// Elementor Pro Lottie animations reset
					$nextContainer.find('.e-lottie__animation').empty();

					// re-init Contact Form 7
					if (typeof wpcf7 !== 'undefined') {
						wpcf7.initForm($cf7Forms);
					}

					// re-init Contact Form 7 Conditional Fields Plugin
					if (typeof wpcf7cf !== 'undefined') {
						wpcf7cf.initForm($cf7Forms);
					}

					// clear & re-init ScrollMagic
					window.SMController.destroy(true);
					window.SMController = new ScrollMagic.Controller();

					// Transition init new page event (before components init)
					window.dispatchEvent(new CustomEvent('arts/barba/transition/init/before'));

					// re-init components
					initComponents($nextContainer);

					// don't start animations immediately
					window.SMController.enabled(false);

					// scroll at the page beginning
					scrollToVeryTop();

					if (window.theme.evalInlineContainerScripts) {

						// eval inline scripts in the main container
						$nextContainer.find('script').each(function () {
							try {
								window.eval(this.text);
							} catch (error) {
								console.warn(error);
							}
						});

					}

					// ensure that scroll is still locked
					lockScroll(true);

					// Transition init new page event (after components init)
					window.dispatchEvent(new CustomEvent('arts/barba/transition/init/after'));

					// init Elementor frontend
					if (typeof window.elementorFrontend !== 'undefined') {
						elementorFrontend.init();
					}

					// update ad trackers
					PJAXUpdateTrackers();

					resolve(true);

				})
				.catch((e) => {
					barba.force(data.next.url.href);
					console.warn(e);
				});

		});

	});

}
