/* ======================================================================== */
/* 41. createOSScene */
/* ======================================================================== */
function createOSScene($el, tl, $customTrigger, noReveal = false, animDelay = 0) {

	var
		$trigger = $el,
		scale,
		masterTL = new TimelineMax();

	if ($customTrigger && $customTrigger.length) {
		$trigger = $customTrigger;
	}

	if (!noReveal) {
		// reveal hidden element first
		$el.attr('data-os-animation', 'animated');
	}

	if (animDelay) {
		masterTL.delay(animDelay);
	}

	masterTL
		.add([tl, function () {

			// update scrollbar geometry
			if (window.SB !== undefined) {
				window.SB.update();
			}

		}], '0');

	// set animation reveal master speed
	if (window.theme !== undefined) {

		scale = window.theme.animations.timeScale.onScrollReveal || 1;
		masterTL.timeScale(scale);

	}

	new $.ScrollMagic.Scene({
		triggerElement: $trigger,
		triggerHook: window.SMSceneTriggerHook,
		reverse: window.SMSceneReverse
	})
		.setTween(masterTL)
		.addTo(window.SMController);

}
