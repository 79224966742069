/**
 * Try to use high performance GPU on dual-GPU systems
 */
runOnHighPerformanceGPU();

window.$document = $(document);
window.$window = $(window);
window.$body = $('body');
window.$html = $('html');
window.$pageContent = $('.page-wrapper__content');
window.$pageHeader = $('.header');
window.$overlay = $('.header__wrapper-overlay-menu');
window.$barbaWrapper = $('[data-barba="wrapper"]');
window.PagePreloader = new Preloader();
window.$curtain = $('.transition-curtain');
window.triggerTextAlign = 'left';
window.$spinner = $('.js-spinner');

/**
 * Begin Page Load
 */
window.PagePreloader.start();

/**
 * Default Theme Options
 * Used to prevent errors if there is
 * no data provided from backend
 */
if (typeof window.theme === 'undefined') {
	window.theme = {
		fonts: ['Roboto', 'Playfair Display'],
		customPreventRules: '',
		evalInlineContainerScripts: false,
		animations: {
			triggerHook: 0.85,
			timeScale: {
				onScrollReveal: 1,
				overlayMenuOpen: 1,
				overlayMenuClose: 1.5,
			},
			flyingHeadingsStagger: 0.15,
			cursorAttractionDelay: 0.04
		},
		smoothScroll: {
			damping: 0.06,
			renderByPixels: true,
			continuousScrolling: false,
			plugins: {
				edgeEasing: true
			}
		},
		audio: {
			playing: {
				label: 'Sound on',
				icon: 'pause'
			},
			paused: {
				label: 'Sound off',
				icon: 'play'
			}
		},
		mobileBarFix: {
			enabled: true,
			update: true
		}
	}
}

/**
 * ScrollMagic Setup
 */
window.SMController = new ScrollMagic.Controller();
window.SMController.enabled(false);
window.SMSceneTriggerHook = window.theme.animations.triggerHook;
window.SMSceneReverse = false;

/**
 * Don't save scroll position
 * after AJAX transition
 */
if ('scrollRestoration' in history) {
	history.scrollRestoration = 'manual';
}

/**
 * Page Load Strategy
 */
$window.on('load', function () {

	fontObserver()
		.then(function () {
			return doSplitText();
		})
		.then(function () {
			return setLines();
		})
		.then(function () {
			return setChars();
		})
		.then(function () {
			lazyLoad(window.$document);
			initComponents(window.$document);
			return window.PagePreloader.finish();
		})
		.then(function () {
			window.SMController.enabled(true);
			window.SMController.update(true);
			window.$body.removeClass('cursor-progress');
		});

	new PJAX();

	if ($('#js-audio-background').length) {
		window.AudioBackground = new AudioBackground(window.$document);
		window.AudioBackground.run();
	}

	window.InteractiveCursor = new Cursor();

});

/**
 * Init Template Components
 */
function initComponents($scope = window.$document) {

	new SmoothScroll();

	window.PageHeader = new Header();

	if (typeof window.PageMenu === 'undefined') {
		window.PageMenu = new MenuOverlay();
	}

	new Grid();
	new SectionGrid($scope);
	new SectionMasthead($scope);
	new SectionPortfolio($scope);
	new SectionNavProjects($scope);
	new SectionFullscreenSlider($scope);
	new SectionContent($scope);
	new SectionAbout($scope);
	new SectionServices($scope);
	new SectionTestimonials($scope);
	new SectionAwards($scope);
	new SectionProperties($scope);
	new SliderImages($scope);
	new SliderProjects($scope);
	new SliderLetters($scope);
	$('.js-video').magnificPopup();
	new ScrollDown();
	new Form();
	new GMap($scope);
	new ButtonCircle($scope);
	if (window.theme.mobileBarFix.enabled) {
		fixMobileBarHeight();
	}
	lazyLoad($scope);
	objectFitVideos(document.querySelectorAll('video.of-cover'));

	$('[data-art-parallax]').artParallax({
		ScrollMagicController: window.SMController,
		SmoothScrollController: window.SB
	});

	// refresh animation triggers
	// for Waypoints library
	if (typeof Waypoint !== 'undefined') {
		Waypoint.refreshAll();
	}

	// custom JS code
	if (window.theme.customJSInit) {
		try {
			window.eval(window.theme.customJSInit);
		} catch (error) {
			console.warn(error);
		}
	}

	// anchors AJAX smooth scrolling
	if (window.location.hash && !window.location.hash.startsWith('#elementor-action')) {
		var
			$scrollElement = $(window.location.hash),
			$preloader = $('.js-preloader'),
			time = 800,
			timeout = $preloader.length ? 1500 : 300;

		if ($scrollElement.length) {
				var offsetY = $scrollElement.offset().top;

			setTimeout(() => {
				if (typeof window.SB !== 'undefined') {
					window.SB.scrollTo(0, offsetY, time);
				} else {
					$('html, body').animate({
						scrollTop: offsetY
					}, time);
				}
	
			}, timeout);
	
		}
	}	

}
