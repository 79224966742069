/* ======================================================================== */
/* 50. sectionFullscreenSlider */
/* ======================================================================== */
var SectionFullscreenSlider = function ($scope) {

	var $target = $scope.find('.section-fullscreen-slider[data-os-animation]');

	if (!$target.length) {

		return;

	}

	$target.each(function () {

		var
			tl = new TimelineMax(),
			$current = $(this),
			$canvasWrapper = $current.find('.slider__wrapper-canvas'),
			slider,
			$slider = $current.find('.js-slider-fullscreen'),
			$sliderImages = $current.find('.js-slider-fullscreen__images'),
			effectIntensity = $sliderImages.data('transition-effect-intensity') || 0.25,
			speed = $sliderImages.data('speed') / 1000 || 1.2,
			$buttonWrapper = $slider.find('.slider__wrapper-button');

		function prepare() {

			return new Promise(function (resolve, reject) {

				var tl = new TimelineMax();

				tl
					.set($buttonWrapper, {
						autoAlpha: 0,
						y: '20px'
					})
					.set($canvasWrapper, {
						scale: 1.1,
						autoAlpha: 0,
						transformOrigin: 'center center'
					})
					.add(function () {
						slider = new SliderFullScreen($slider);
						if (slider.params.autoplay.enabled) {
							slider.autoplay.stop();
						}
					})
					.add(function () {
						resolve(true);
					});

			});

		}

		function animate() {

			var
				$activeSlide = $target.find('.swiper-slide-active'),
				$activeHeading = $activeSlide.find('.slider__heading'),
				$activeSubheading = $activeSlide.find('.slider__subheading'),
				$activeDescription = $activeSlide.find('.slider__text'),
				$activeButton = $activeSlide.find('.slider__wrapper-button');

			$activeSlide.imagesLoaded({
				background: true,
			}, function () {


				tl
					.add(
						function () {

							if (slider.params.autoplay.enabled) {
								slider.autoplay.start();
							}

							if (typeof slider.distortionEffect !== 'undefined') {
								TweenMax.to($canvasWrapper, speed * 1.5, {
									scale: 1,
									autoAlpha: 1,
									ease: Power3.easeOut
								});

								slider.distortionEffect.animate();
								slider.distortionEffect.change({
									indexFrom: '0',
									indexTo: '0',
									speedIn: speed * 1.5,
									intensity: effectIntensity,
									easing: Power3.easeOut,
									direction: 'vertical'
								});

								window.$window.one('arts/barba/transition/start', () => {
									slider.distortionEffect.destroy();
								});

							}

						}
					)
					.add(animateChars($activeHeading, 1.2, 0.3, Power3.easeOut), '+=0.6')
					.add(animateChars($activeSubheading, 1.2, 0.3, Power3.easeOut), '-=1.2')
					.add(animateLines($activeDescription, 1.2, 0.04), '-=1.2')
					.to($activeButton, 1.2, {
						y: '0px',
						autoAlpha: 1,
						ease: Power3.easeOut
					}, '-=1.2');

				createOSScene($current, tl);

			});

		}

		$sliderImages.imagesLoaded().always(() => {
			prepare().then(() => {
				animate();
			});
		});

	})

}
