/* ======================================================================== */
/* 11. PJAX Update Admin Bar */
/* ======================================================================== */
function PJAXUpdateAdminBar(data) {

	return new Promise(function (resolve, reject) {

		var
			$elementorAdminBarConfigScript = $('#elementor-admin-bar-js-before'),
			$currentBar = $('#wpadminbar');

		if (!$currentBar.length) {
			resolve(true);
			return;
		}

		var
			rawHTML = $.parseHTML(data.next.html),
			$newBar = $(rawHTML).filter('#wpadminbar');

		$newBar.find('.hide-if-no-customize').removeClass('hide-if-no-customize');
		$currentBar.replaceWith($newBar);

		// re-init Elementor admin bar buttons
		if ($elementorAdminBarConfigScript.length) {
			try {
				var nextElementorAdminBarConfigScript = data.next.html.match(/(?:'|")elementor-admin-bar-js-before(?:'|")>([\s\S.]*?)(?:<\/script>)/i)[1];

				// update the script contents
				$elementorAdminBarConfigScript.html(nextElementorAdminBarConfigScript);

				// eval new script params
				window.eval($elementorAdminBarConfigScript.text());

				// fetch Elementor admin bar script to re-initialize it
				$.get(`${document.location.origin}/wp-content/plugins/elementor/assets/js/elementor-admin-bar.min.js`);
			} catch (err) {
				console.warn(err);
			}
		}

		resolve(true);

	});

}
