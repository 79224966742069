/* ======================================================================== */
/* 84. runOnHighPerformanceGPU */
/* ======================================================================== */
function runOnHighPerformanceGPU() {
	const
		$webGLCanvas = $('#js-webgl'),
		$otherCanvas = $('canvas:not(#js-webgl)');

	if (!$otherCanvas.length && !window.Modernizr.touchevents && $webGLCanvas.length) {
		$webGLCanvas[0].getContext('webgl', {
			powerPreference: 'high-performance'
		});
	}

}
