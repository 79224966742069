/* ======================================================================== */
/* 19. PJAX */
/* ======================================================================== */
var PJAX = function () {

	var $barbaWrapper = $('[data-barba="wrapper"]');

	if (!$barbaWrapper.length) {
		return;
	}

	barba.init({

		timeout: 10000,

		// don't trigger barba for links outside wrapper 
		prevent: ({
			el
		}) => {

			var
				$el = $(el),
				url = $el.attr('href'),
				customRules = sanitizeSelector(window.theme.customPreventRules),
				exludeRules = [
					'.no-ajax',
					'.no-ajax a',
					'[data-elementor-open-lightbox]', // Elementor lightbox gallery
					'[data-elementor-lightbox-slideshow]', // Elementor Pro Gallery
					'.lang-switcher a' // Polylang & WPML language switcher
				];

			if (
				url === '#' || // dummy link
				url.indexOf('wp-admin') > -1 ||	// WordPress admin link
				url.indexOf('wp-login') > -1 || // WordPress login link
				url.indexOf('/feed/') > -1 // WordPress feed
			) {
				return true;
			}

			// page anchor
			if ($el.is('[href*="#"]') && window.location.href === url.substring(0, url.indexOf('#'))) {
				return true;
			}

			// elementor preview
			if (window.theme.isElementorEditorActive) {
				return true;
			}

			// clicked on elementor ouside barba wrapper
			if ($el.closest($barbaWrapper).length < 1) {
				return true;
			}

			// custom rules from WordPress Customizer
			if (customRules) {
				exludeRules = [...exludeRules, ...customRules.split(',')];
				exludeRules = [...new Set(exludeRules)];
			}

			// check against array of rules to prevent
			return $el.is(exludeRules.join(','));

		},
		// custom transitions
		transitions: [
			PJAXTransitionGeneral,
			PJAXTransitionFlyingHeading,
			PJAXTransitionOverlayMenu,
		]

	});


}
