/* ======================================================================== */
/* 54. SectionProperties */
/* ======================================================================== */
var SectionProperties = function ($scope) {

	var
		$target = $scope.find('.section-properties[data-os-animation]');

	if (!$target.length) {
		return;
	}

	$target.attr('data-os-animation', 'animated');

	$target.each(function () {

		var
			$current = $(this),
			$figures = $current.find('.figure-info');

		$figures.each(function () {

			var
				tl = new TimelineMax(),
				$currentFigure = $(this),
				$option = $currentFigure.find('.figure-info__option'),
				$value = $currentFigure.find('.figure-info__value');

			tl
				.add(animateLines($option, 0.6, 0.07))
				.add(animateLines($value, 0.6, 0.07), '-=0.5')

			createOSScene($currentFigure, tl);

		});

	});

}
