/* ======================================================================== */
/* 39. preloader */
/* ======================================================================== */
function Preloader() {

	var
		tl = new TimelineMax(),
		$preloader = $('.js-preloader'),
		$curtainInner = $preloader.find('.preloader__curtain_inner'),
		$curtainOuter = $preloader.find('.preloader__curtain_outer'),
		$circle = $preloader.find('.circle'),
		$logo = $preloader.find('.preloader__content'),
		$pageContent = $('.page-wrapper__content'),
		$counter = $preloader.find('.js-counter'),
		$num = $counter.find('.js-counter__number'),
		numberStart = 1,
		numberTarget = 100,
		animDuration = 25,
		counter = {
			val: numberStart
		};


	if (window.theme !== 'undefined') {

		var scale = window.theme.animations.timeScale.preloader || 0.9;
		tl.timeScale(scale);

	}

	this.start = function () {

		if (!$preloader.length) {
			return;
		}

		TweenMax.fromTo($circle, animDuration, {
			rotation: 0,
			drawSVG: '100% 100%'
		}, {
			drawSVG: '0% 100%',
			rotation: 90,
			transformOrigin: 'center center',
			ease: Power4.easeOut,
		});

		TweenMax.set($pageContent, {
			y: '20vh'
		});

		TweenMax.to(counter, animDuration, {
			val: numberTarget.toFixed(0),
			ease: Power4.easeOut,
			onUpdate: function () {
				var value = counter.val.toFixed(0);
				if (value < 10) {
					value = '0' + value;
				}
				$num.text(value);
			}
		})

	}

	this.finish = function () {
		return new Promise(function (resolve, reject) {

			if (!$preloader.length) {
				resolve(true);
				return;
			}

			tl
				.clear()
				.to($circle, 1.2, {
					drawSVG: '0% 100%',
					rotation: 90,
					transformOrigin: 'center center',
					ease: Expo.easeInOut,
				})
				.to(counter, 1.2, {
					val: numberTarget.toFixed(0),
					ease: Expo.easeInOut,
					onUpdate: function () {
						var value = counter.val.toFixed(0);
						if (value < 10) {
							value = '0' + value;
						}
						$num.text(value);
					}
				}, '-=1.2')
				.add([
					TweenMax.to($curtainInner, 1.2, {
						y: '-100%',
						ease: Expo.easeInOut,
					}),
					TweenMax.to($curtainOuter, 1.2, {
						y: '-100%',
						ease: Expo.easeInOut
					}),
					TweenMax.to($logo, 0.6, {
						y: '-25%',
						ease: Power3.easeOut,
						autoAlpha: 0
					}),
					TweenMax.to($pageContent, 1.4, {
						y: '0vh',
						ease: Expo.easeInOut,
						onComplete: function () {
							TweenMax.set($pageContent, {
								clearProps: 'all'
							});
						}
					})
				])
				.add(function () {
					resolve(true);
				}, '-=1.0')
				.set($preloader, {
					display: 'none'
				});

		});
	}

}
