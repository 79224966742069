/* ======================================================================== */
/* 7. PJAX Prepare Transition */
/* ======================================================================== */
function PJAXPrepareTransition(data) {

	return new Promise(function (resolve, reject) {

		var
			tl = new TimelineMax(),
			$trigger = $(data.trigger),
			$heading = $trigger.find('.js-text-to-fly');

		// Transition started event
		window.dispatchEvent(new CustomEvent('arts/barba/transition/start'));

		if (!$heading.length) {
			$heading = $trigger.parent().parent().find('.js-text-to-fly');
		}

		if (!$heading.length) {
			$heading = $trigger;
		}

		TweenMax.to(window.$spinner, 0.6, {
			autoAlpha: 1
		});

		tl
			.add(function () {
				window.InteractiveCursor.startLoading();
				window.$body.addClass('cursor-progress');
			})
			.set(window.$curtain, {
				display: 'block',
				y: '100%',
				zIndex: 550
			})
			.set($trigger, {
				className: '+=selected'
			})
			.add(function () {
				lockScroll(true);
				window.triggerTextAlign = $heading.css('text-align');
				window.$document.off('click');
				resolve(true);
			});

	});

}
